import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'menu-dashboard',
        title: 'Main Dashboard',
        type: 'item',
        url: '/dashboard/home',
        icon: 'feather icon-home',
        classes: 'nav-item',
        breadcrumbs: true,
      },
      {
        id: 'menu-institution',
        title: 'Institution Profile',
        type: 'item',
        url: '/institution/profile',
        icon: 'feather icon-layers',
        classes: 'nav-item',
        breadcrumbs: true,
      },
    ],
  },
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
