import { Component, OnInit, OnChanges } from '@angular/core';
import {Input, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {

  @Input() list: any = [];
  @Input() placeholderName: any = '';
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  showDropDown: any = false;
  checkedList: any = [];
  currentSelected: {};

  constructor() {
    this.checkedList = [];
    // console.log(this.checkedList);
  }

  ngOnInit(): void {
    // console.log(this.checkedList);
  }
  ngOnChanges(): void {
    // console.log(this.checkedList);
    this.checkedList = [];
  }
  getSelectedValue(status: boolean, data): any {
    if (status) {
      if (data.id === 'all') {
        this.checkedList = [];
        this.list.map((row, i) => {
          if (row.id !== 'all') {
            this.checkedList.push(row);
          }
          this.list[i].checked = true;
        });
      } else {
        this.checkedList.push(data);
      }
    } else {
      if (data.id === 'all') {
        this.list.map((row, i) => {
          this.list[i].checked = false;
          if (i === this.checkedList.length - 1) {
            this.checkedList = [];
          }
        });
      } else {
        this.list[0].checked = false;
        if (this.checkedList.length === 1) {
          this.checkedList = [];
        }
        this.checkedList = this.checkedList.filter(row => row.id !== data.id);
        // if (this.checkedList.length === 0) {
        //   this.checkedList = [];
        // }
        // const index = this.checkedList.indexOf(data.id);
        // this.checkedList.splice(index, 1);
      }
    }
    this.currentSelected = {checked : status, name: data.name};
    this.shareCheckedlist();
    this.shareIndividualStatus();
  }
  shareCheckedlist(): any {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus(): any {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
  getName(id): any {
    const res: any = this.list.filter(row => row.id === id);
    if (res.length) {
      return res[0].name;
    }
  }

}
