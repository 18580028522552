import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  async canActivate() {
    let token: any = null;
    token = localStorage.getItem('token');
    if (token) {
      return true;
      // let status: any = false;
      // await this.authService.getMethod('/users/userinfo').toPromise().then(res => {
      //   this.authService.setUserInfo(res);
      //   if (res.status === true) {
      //     status = true;
      //   }
      // }, error => { this.authService.errorMessage(this, error); }
      // );
      // if (status === true) {
      //   return true;
      // }
    }
    localStorage.removeItem('token');
    this.router.navigate(['/auth/signin']);
    return false;
  }
}
