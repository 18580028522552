<div>
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat form-control" (click)="showDropDown=!showDropDown">
            <span *ngIf="checkedList.length <= 0 || list?.length === 0">{{placeholderName}}</span>
            <span *ngIf="checkedList.length === 1 && list?.length">
                <!-- {{checkedList.join(', ')}} -->
                {{checkedList[0].name}}
            </span>
            <span *ngIf="checkedList.length > 1 && list?.length">
                <!-- {{checkedList.join(', ')}} -->
                {{checkedList[0].name + ' +' + (checkedList?.length - 1) + ' Selected'}}
            </span>
            <i class="fa fa-angle-down"></i>
        </button>
        <div class="drop-show" *ngIf="showDropDown">
            <label *ngFor="let a of list">
                <input type="checkbox" [(ngModel)]="a.checked" 
                    (change)="getSelectedValue(a.checked, a)" />
                <span>{{a.name}}</span>
            </label>
        </div>
    </div>
</div>